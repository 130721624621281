<template>
  <svg viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="chevron-down">

    <polyline points="6 9 12 15 18 9" />
  </svg>
</template>

<script>
export default {
  name: 'chevron-down',
}
</script>
