<template>
  <p class="help-with-unexpected-error">
    You likely aren't the only one seeing this issue, and if you feel like
    helping me fix it for everyone you can email me at {{ global.authorEmail }}.
    Please include an explanation of what you did that led to to the error.  If
    I am able to follow your steps and reproduce the issue on my end then we can
    get everyone back enjoying the
    <span class="dont-wrap">game <smile /></span>
  </p>
</template>

<script>
export default {
  name: 'help-with-unexpected-error',
}
</script>
