<template>
  <div class="init-player-info_display-name info">
    <h4>Display Name</h4>
    <p>
      Just the name used to identify you for this game. Use your real name, your
      superhero name or anything you feel like.
    </p>
    <p class="sidenote">(Up to 15 letters)</p>
  </div>
</template>

<script>
export default {
  name: 'init-player-info_display-name',
}
</script>
