<template>
  <svg viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="emoji frown">

    <circle cx="12" cy="12" r="10" />
    <path d="M7 17s1-3 5-3 5 3 5 3" />
    <circle cx="8.5" cy="8" r="1.4" fill="currentColor" stroke="none" />
    <circle cx="15.5" cy="8" r="1.4" fill="currentColor" stroke="none" />
  </svg>
</template>

<script>
export default {
  name: 'frown',
}
</script>
