<template>
  <svg viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    stroke-width="3.2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="close-x">

    <line x1="23" y1="1" x2="1" y2="23" />
    <line x1="1" y1="1" x2="23" y2="23" />
  </svg>
</template>

<script>
export default {
  name: 'close-x',
}
</script>

<style lang="scss">
svg.close-x {
  height: 14px;
  width: 14px;
}
</style>
