<template>
  <div class="warn-unsubscribe-from-room-created warn">
    <p>
      Unsubscribing from 'Room Created' emails means you won't be able to join
      rooms that you create.  This is because, currently, email is the only
      means of receiving the initial link to your&nbsp;game.
    </p>
    <p>
      In the future this may change and I will remove this warning if it does. I
      just prefer allowing everyone to have full control over their unsubscribe
      settings because it's important to me that I don't bother
      <span class="dont-wrap">you <smile /></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'warn-unsubscribe-from-room-created',
}
</script>
