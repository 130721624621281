//------//
// Init //
//------//

const domain = 'test.passthequill.com',
  liveUpdateWebsocketPort = 8081,
  serverPort = 8080

//
//------//
// Main //
//------//

//
// TODO: look into authentication alternatives
// I didn't look into the authentication mechanisms much, but they did note
//   basic auth to be lesser performant.
//
const authorEmail = `phil@${domain}`,
  couchdbAuth = {
    username: 'phil',
    password: 'nEyn7MTRPKbNr479fGIrIQqiaE5gKEhS',
  },
  baseUrl = {
    couchdb: 'http://localhost:5984',
    external: 'https://test.passthequill.com',
    local: `http://localhost:${serverPort}`,
  },
  liveUpdateWebsocket = {
    port: liveUpdateWebsocketPort,
    url: `wss://test.passthequill.com:${liveUpdateWebsocketPort}`,
    pathToCert: '/etc/letsencrypt/live/test.passthequill.com/fullchain.pem',
    pathToKey: '/etc/letsencrypt/live/test.passthequill.com/privkey.pem',
  },
  logDirectory = '/home/phil/logs/test.passthequill.com',
  persistentStaticDir = '/home/phil/persistent-static/test.passthequill.com',
  salt = {
    email: '$2a$11$eMKM3cKwBb/cjQDpc18PW.',
    hashid: 'Mu0MzXl4bppsYZ1USy8L_ZE5IwZ0M9xI',
  },
  smtpAuth = {
    user: 'info@test.passthequill.com',
    pass: 'CWPTZWpM7D1bJiqwrlBAQQ4PHAS_JP9Q',
  }

//
//---------//
// Exports //
//---------//

export {
  authorEmail,
  baseUrl,
  couchdbAuth,
  domain,
  liveUpdateWebsocket,
  logDirectory,
  persistentStaticDir,
  salt,
  serverPort,
  smtpAuth,
}

